/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";

/* Slider/Carousel Library */
import "@js/parts/carousel";

/* Lightbox */
import "@js/parts/lightbox";

/* Image Gallery and Lightbox (PhotoSwipe) */
// import "@js/parts/imagegallery"

/* Artsopolis / Butte365 Calendar API Integration */
//import "@js/parts/artsopolis"

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
