/* Glide.js https://glidejs.com/ */
import Glide from '@glidejs/glide';
import "@glidejs/glide/dist/css/glide.core.css";
import "@glidejs/glide/dist/css/glide.theme.css";

/* Great Tips From: */
/* https://github.com/glidejs/glide/issues/202 */

/* Global Carousel Loader with data-glide element. */
/* Example: 
<div class="glide" data-glide='{
  "type": "carousel",
  "loop": true,
  "perView": 3,
  "perMove": 3,
  "perSwipe": 3
}'></div>
*/
const COMPONENT_NAME = "data-glide";
const COMPONENT_SELECTOR = `[${COMPONENT_NAME}]`;

const defaultBreakpoints = { breakpoints: {
    640: { perView: 2 },
    768: { perView: 3 }
}};

const components = document.querySelectorAll(COMPONENT_SELECTOR);

for (let i = 0; i < components.length; i++) {
  const options = JSON.parse(
    components[i].getAttribute(COMPONENT_NAME) || "{}"
  );

  Object.assign(options, defaultBreakpoints);

  let glide = new Glide(
    components[i],
    options
  );

  glide.mount();
}

/* Set up 3 carousel styles for the category block. */
const glideOptionsCategoryLeft = {
    type: 'carousel',
    startAt: 0,
    focusAt: 0,
    perView: 2,
    gap: 16,
    peek: { 
        before: 0,
        after: 500 
    },
    breakpoints: {
        640: { 
            perView: 2,
            peek: { 
                before: 0,
                after: 0 
            },
        },
        768: { 
            perView: 3,
            peek: { 
                before: 0,
                after: 0 
            },
        },
        900: { 
            perView: 1
        }
    }
};

const glideOptionsCategoryRight = {
    type: 'carousel',
    startAt: 0,
    focusAt: 0,
    perView: 2,
    gap: 16,
    peek: { 
        before: 500,
        after: 0 
    },
    breakpoints: {
        640: { 
            perView: 2,
            peek: { 
                before: 0,
                after: 0 
            },
        },
        768: { 
            perView: 3,
            peek: { 
                before: 0,
                after: 0 
            },
        },
        900: { 
            perView: 1
        }
    }
}

const glideOptionsCategoryCenter = {
    type: 'carousel',
    startAt: 0,
    focusAt: 0,
    perView: 3,
    gap: 16,
    breakpoints: {
      490: {
        perView: 1,
          peek: { 
              before: 0,
              after: 0 
          },
      },
      640: { 
          perView: 2,
          peek: { 
              before: 0,
              after: 0 
          },
      },
      768: { 
          perView: 3,
          peek: { 
              before: 0,
              after: 0 
          },
      },
      900: { 
          perView: 3
      }
  }
}

const carouselsCategoryLeft = document.querySelectorAll(".glide-category-left");
const carouselsCategoryRight = document.querySelectorAll(".glide-category-right");
const carouselsCategoryCenter = document.querySelectorAll(".glide-category-center");

Object.values(carouselsCategoryLeft).map(carousel => {
      new Glide(carousel, glideOptionsCategoryLeft).mount();
});
Object.values(carouselsCategoryRight).map(carousel => {
    new Glide(carousel, glideOptionsCategoryRight).mount();
});
Object.values(carouselsCategoryCenter).map(carousel => {
    new Glide(carousel, glideOptionsCategoryCenter).mount();
});
